<template>
  <b-modal id="activate-product" title="Product Activation" ok-title="Save" ref="modal" @close="handleCancel"
    @cancel="handleCancel" @ok="handleOk" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
    :no-close-on-backdrop="true">
    <br />
    <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
    <div>
      <span v-if="isActive">
        Are you sure you want to
        <b>deactivate</b>
        product
        <b>{{ selProduct.title }}?</b>
      </span>
      <span v-else>
        Are you sure you want to
        <b>activate</b>
        product
        <b>{{ selProduct.title }}?</b>
      </span>
    </div>
    <br />
  </b-modal>
</template>

<script>
// DAO
import productDAO from '../../database/products'

// Util
import dateUtil from '../../utils/dateUtil'

// Others
import { bus } from '../../main'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'activate-product',
  components: {
    Loading,
  },
  data() {
    return {
      selProduct: {},
      isActive: true,

      // Check for loader
      isLoading: false,
      loggedUser: this.$store.getters['auth/loggedUser'],
    }
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading
    },
  },
  mounted() {
    bus.$on('onUpdateSelProduct', (selProduct) => {
      this.selProduct = Object.assign({}, selProduct)
      this.isActive = selProduct.isActive
    })
  },
  methods: {
    resetStatus() {
      this.selProduct.isActive = this.isActive
      this.$store.commit('SET_CURR_PRODUCT', this.selProduct)
    },
    async handleCancel() {
      this.resetStatus()
      bus.$emit('onCloseActivateProduct', this.selProduct)
      this.isLoading = false
    },
    async handleOk() {
      try {
        // Show loading indicator
        this.isLoading = true

        // Update status
        this.selProduct.isActive = !this.isActive
        // Update Timestamps
        this.selProduct.updatedBy = this.loggedUser
        this.selProduct.dateUpdated = dateUtil.getCurrentTimestamp()

        await productDAO.updateProduct(this.selProduct, this.selProduct.id)

        let activationLabel = this.selProduct.isActive
          ? 'activated'
          : 'deactivated'
        this.$toast.success(
          `Product ${this.selProduct.name} account is now ${activationLabel}!`,
          'Success',
        )
        this.$refs.modal.hide()
        bus.$emit('onCloseActivateProduct', this.selProduct)
      } catch (error) {
        console.error(error)
        let errorLabel = this.selProduct.isActive
          ? 'activating'
          : 'deactivating'
        this.$toast.error(
          `There is an error ${errorLabel} product ${this.selProduct.name}. Please try again.`,
          'Error',
        )
      }

      // Hide loading indicator
      this.isLoading = false
    },
  },
  beforeDestroy() {
    bus.$off('onUpdateSelProduct')
  },
}
</script>
