<template>
  <b-modal id="reorder-product" size="lg" title="Re-order Products" ref="modal" @ok="handleOk" @show="onReset"
    :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
    <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
    
    <div class="row">
			<div class="col-md-12">
				<b-alert show class="info">Click and drag to reoder items</b-alert>
				<draggable v-model="items" ghost-class="ghost" class="list-group">
					<transition-group>
						<b-row
							class="
								list-group-item
								d-flex
                item-margin
							"
              v-for="(item, index) in orderedList" :key="item.id">
                <b-col>{{ index + 1 }}</b-col>
                <b-col align="left" sm="10">{{ item.name }}</b-col>
            </b-row>
					</transition-group>
				</draggable>
			</div>
		</div>

    <template #modal-footer="{ ok, cancel }">
      <base-button :disabled="isLoading" type="secondary" class="text-dark" @click="cancel()">
        Cancel
      </base-button>
      <base-button :disabled="isLoading" type="primary" class="text-dark" @click="ok()">
        Update List
      </base-button>
    </template>
  </b-modal>
</template>

<script>

// DAO
import productDAO from "../../database/products";

// Util
import asyncForEach from "../../utils/asyncForEach";

// Others
import { bus } from "../../main";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import draggable from 'vuedraggable';
import _ from 'lodash'

export default {
  name: "reorder-product",
  components: {
    Loading,
    draggable,
  },
  data() {
    return {
			enabled: true,
			items: [],

      isLoading: false,
      loggedUser: this.$store.getters["auth/loggedUser"],
    };
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading;
    },
    orderedList() {
			return this.items.map((item, index) => {
				return {
					...item,
					order: index + 1,
				};
			});
		},
  },
  mounted() {
		bus.$on('ReorderProductDialog', (items) => {
			this.items = _.orderBy(items, ['order'], ['asc']);
			this.$bvModal.show('reorder-product');
		});
	},
  methods: {
    
    async handleOk(evt) {
      evt.preventDefault();

      this.isLoading = true;

      try {
				await asyncForEach(this.orderedList, async (item) =>
					await productDAO.updateProduct(item)
				);

				this.$bvModal.hide('reorder-product');
				this.$toast.success('Reordering Products has been updated!');
				bus.$emit('onCloseReOrderProduct');
			} catch (error) {
				this.$toast.error('Error reordering products.', "Error");
			}

			// hide loading indicator
			this.isLoading = false;
    },

    onReset() {
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>

.info {
  background-color: #eda71146;
  color: black;
}

.item-margin {
  margin: 0px 0px 0px 0px;
}

</style>
