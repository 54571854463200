<template>
  <b-modal id="add-product" size="lg" title="Add Product" ref="modal" @ok="handleOk" @show="onReset"
    :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
    <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
    <b-form @submit.stop.prevent="handleSubmit" novalidate>
      <div class="form-row">
        <!-- Product Name Field -->
        <div class="col-md-6">
          <b-form-group label="Name" label-for="name" description>
            <b-form-input name="Name" type="text" v-model="form.name" v-validate="{
              required: true,
              regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,
            }" placeholder="Product Name" maxlength="100" />
            <span v-show="errors.has('Name')" class="help-block">
              {{ errors.first("Name") }}
            </span>
          </b-form-group>
        </div>
        <!-- Description Field -->
        <div class="col-md-6">
          <b-form-group label="Description" label-for="description" description>
            <b-form-input name="Description" type="text" v-model="form.description" v-validate="{
              required: true,
              regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,
            }" required placeholder="Description" maxlength="100" />
            <span v-show="errors.has('Description')" class="help-block">
              {{ errors.first("Description") }}
            </span>
          </b-form-group>
        </div>
      </div>
      <!-- Project Name Field -->
      <div class="form-row">
        <div class="col-md-4">
          <b-form-group label="Project Name" label-for="projectName" description>
            <b-form-input name="Project Name" type="text" v-model="form.projectName" v-validate="{
              required: true,
              regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,
            }" required placeholder="Project Name" maxlength="100" />
            <span v-show="errors.has('Project Name')" class="help-block">
              {{ errors.first("Project Name") }}
            </span>
          </b-form-group>
        </div>
        <!-- Type Field -->
        <div class="col-md-4">
          <b-form-group label="Type" label-for="type" description>
            <b-form-select name="Type" type="text" v-model="form.type" :options="options" v-validate="'required'" />
            <span v-show="errors.has('Type')" class="help-block">
              {{ errors.first("Type") }}
            </span>
          </b-form-group>
        </div>
        <!-- Client Name Field -->
        <div class="col-md-4">
          <b-form-group label="Client Name" label-for="client name" description>
            <b-form-input name="Client Name" type="text" v-model="form.clientName" v-validate="{
              required: true,
              regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,
            }" placeholder="Client Name" maxlength="100" />
            <span v-show="errors.has('Client Name')" class="help-block">
              {{ errors.first("Client Name") }}
            </span>
          </b-form-group>
        </div>
      </div>

      <!-- isHighlight -->
      <div>
        <b-form-group label="Highlight?" label-for="ishighlight" description>
          <input class="ml-3" type="radio" id="true" value="true" v-model="picked" />
          <label class="ml-2" for="true">Yes</label>

          <input class="ml-3" type="radio" id="false" value="false" v-model="picked" />
          <label class="ml-2" for="false">No</label>
        </b-form-group>
      </div>

      <div class="form-row">
        <!-- Actual Image -->
        <div class="col-md-6">
          <b-form-group label="Actual Image" label-for="actualImage">
            <b-form-file id="actualImage" placeholder="Choose a file or drop it here..." ref="file-input-1"
              accept="image/*" @change="onSelectActualImage" no-drop>
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
              </template>
            </b-form-file>
            <p class="badge badge-danger bg-transparent ml-3" v-if="fileSizeExceeded1">
              File size exceeded the limit of {{ maxFileSize / 1000000 }} MB
            </p>
          </b-form-group>

          <!-- Preview Images -->
          <b-row v-if="actualImage.url" class="form-row">
            <b-col md="12">
              <b-card overlay :img-src="actualImage.url" img-alt="Card Image" text-variant="white">
                <b-card-text class="text-right">
                  <base-button type="danger" icon="fa fa-trash" size="sm" @click="onRemoveActualImage" />
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </div>

        <!-- Product Image -->
        <div class="col-md-6">
          <b-form-group label="Product Image" label-for="productImage">
            <b-form-file id="productImage" placeholder="Choose a file or drop it here..." ref="file-input-2"
              accept="image/*" @change="onSelectProductImage" no-drop>
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
              </template>
            </b-form-file>
            <p class="badge badge-danger bg-transparent ml-3" v-if="fileSizeExceeded2">
              File size exceeded the limit of {{ maxFileSize / 1000000 }} MB
            </p>
          </b-form-group>
          <!-- Preview Images -->
          <b-row v-if="productImage.url" class="form-row">
            <b-col md="12">
              <b-card overlay :img-src="productImage.url" img-alt="Card Image" text-variant="white">
                <b-card-text class="text-right">
                  <base-button type="danger" icon="fa fa-trash" size="sm" @click="onRemoveProductImage" />
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <!-- Text Editor -->
        <b-form-group label="Specification" label-for="specification">
          <vue-editor v-model="form.specification" />
        </b-form-group>
      </div>
    </b-form>

    <template #modal-footer="{ ok, cancel }">
      <div class="row" v-if="uploading.uploadStatus === 'uploading'">
        <div class="col-md-12">
          <base-progress class="py-0 my-0" type="danger" :value="uploading.percentage" :label="uploading.filename"
            animated></base-progress>
        </div>
      </div>
      <div class="row" v-else-if="uploading.uploadStatus === 'success'">
        <div class="col-md-12">
          <b-alert variant="success" class="py-2 my-0" show>
            <i class="fa fa-check"></i>
            &nbsp; Successfully saved!
          </b-alert>
        </div>
      </div>

      <base-button :disabled="isLoading" type="secondary" class="text-dark" @click="cancel()">
        Close
      </base-button>
      <base-button :disabled="isLoading" type="primary" class="text-dark" @click="ok()">
        Save
      </base-button>
    </template>
  </b-modal>
</template>

<script>
// DAO
import productDAO from "../../database/products";

// Util
import dateUtil from "../../utils/dateUtil";

//Vue2Editor
import { VueEditor } from "vue2-editor";

// Others
import { bus } from "../../main";
import { storage } from "../../config/firebase";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "add-product",
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      form: {
        id: "",
        name: "",
        description: "",
        type: null,
        isHighlight: null,
        projectName: "",
        specification: "",
        clientName: "",
        actualImage: {},
        productImage: {},
        isActive: true,
        createdBy: "",
        dateCreated: dateUtil.getCurrentTimestamp(),
        updatedBy: "",
        dateUpdated: dateUtil.getCurrentTimestamp(),
      },
      options: [
        { value: "null", text: " - Please select - " },
        { value: "Pre-cast Fence", text: "Pre-cast Fence" },
        { value: "Hybrid CHB", text: "Hybrid CHB" },
      ],
      actualImage: {},
      productImage: {},
      picked: "false",
      uploading: {
        filename: "",
        percentage: 0,
        uploadStatus: "", // [uploading, success, error]
      },

      isLoading: false,
      loggedUser: this.$store.getters["auth/loggedUser"],
      isUploading: false,
      maxFileSize: 2 * 1024 * 1024, //2MB
      fileSizeExceeded1: false,
      fileSizeExceeded2: false,
    };
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading;
    },
  },
  methods: {
    async firebaseUploadImage(image) {
      let filename = `PRODUCT-${Date.now()}`;
      this.uploadingInProgress("uploading", filename, 0);

      return new Promise((resolve, reject) => {
        let storageRef = storage.ref(`images/products/${filename}`);
        let task = storageRef.put(image.file);

        task.on(
          "state_changed",
          (snapshot) => {
            let percentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.uploadingInProgress("uploading", filename, percentage);
          },
          (error) => {
            reject(error);
            this.resetUploadingState();
          },
          () => {
            task.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve({ name: filename, url: downloadURL });
            });
          }
        );
      });
    },
    async handleOk(evt) {
      evt.preventDefault();

      if (!(await this.$validator.validateAll())) {
        this.$toast.warning(
          "Please address the field/s with invalid input.",
          "Warning"
        );
        this.isLoading = false;
        return;
      }

      await this.handleSubmit();
    },

    async handleSubmit() {
      try {
        this.isLoading = true;

        // Update Timestamps
        this.form.createdBy = this.loggedUser;
        this.form.dateCreated = dateUtil.getCurrentTimestamp();

        // Save the product details to the database
        const result = await productDAO.createProduct(this.form);
        if (result.isSuccess) {
          console.log("finish saving data");

          let product = result.product;

          product.isHighlight = this.picked === "true";
          product.actualImage = await this.firebaseUploadImage(
            this.actualImage
          );
          product.productImage = await this.firebaseUploadImage(
            this.productImage
          );

          await productDAO.updateProduct(product);
          console.log("Done uploading images");
          console.log("done with all tasks");

          this.uploadingInProgress("success");
          this.$toast.success("New product was added", "Success!");

          this.$refs.modal.hide();
          bus.$emit("onCloseAddProduct", this.form);

          setTimeout(() => {
            this.resetUploadingState();
          }, 2000);
        } else {
          this.$toast.error(
            "There is an error adding a product. Please try again.",
            "Error"
          );
        }
      } catch (error) {
        console.error(error);
        this.$toast.error(
          "There is an error adding a product. Please try again.",
          "Error"
        );
      }
      // hide loading indicator
      this.isLoading = false;
    },
    uploadingInProgress(uploadStatus, filename = "", percentage = 0) {
      this.uploading.filename = filename;
      this.uploading.percentage = parseFloat(percentage.toFixed(2));
      this.uploading.uploadStatus = uploadStatus;
    },
    resetUploadingState() {
      this.uploading = {
        filename: "",
        percentage: 0,
        uploadStatus: "",
      };
    },
    onRemoveActualImage() {
      this.actualImage = "";
      if (this.$refs["file-input-1"]) {
        this.$refs["file-input-1"].reset();
      }
    },
    onSelectActualImage(evt) {
      const file = evt.target.files[0];
      this.actualImage = {
        url: URL.createObjectURL(file),
        file: file,
      };
      if (file.size > this.maxFileSize) {
        this.fileSizeExceeded1 = true;
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        this.fileSizeExceeded1 = false;
        // do something with the file
      };
      reader.readAsArrayBuffer(file);
    },
    onRemoveProductImage() {
      this.productImage = "";
      if (this.$refs["file-input-2"]) {
        this.$refs["file-input-1"].reset();
      }
    },
    onSelectProductImage(evt) {
      const file = evt.target.files[0];
      this.productImage = {
        url: URL.createObjectURL(file),
        file: file,
      };
      if (file.size > this.maxFileSize) {
        this.fileSizeExceeded2 = true;
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        this.fileSizeExceeded2 = false;
        // do something with the file
      };
      reader.readAsArrayBuffer(file);
    },

    onReset() {
      this.form.name = "";
      this.form.description = "";
      this.form.type = null;
      this.form.isHighlight = null;
      this.form.projectName = "";
      this.form.specification = "";
      this.form.clientName = "";
      this.form.actualImage = {};
      this.form.productImage = {};
      this.form.isActive = true;
      this.form.createdBy = "";
      this.form.dateCreated = dateUtil.getCurrentTimestamp();
      this.form.updatedBy = "";
      this.form.dateUpdated = dateUtil.getCurrentTimestamp();

      // reset validation
      this.isLoading = false;
      this.$validator.reset();
      this.errors.clear();
      this.onRemoveActualImage();
      this.onRemoveProductImage();
    },
  },
};
</script>
