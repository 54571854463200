import { db } from "../config/firebase";

// Util
import formatUtil from '../utils/formatUtil';

async function getProductsById(id) {
  let productObj = {};

  let query = db.collection("products").where("id", "==", id).limit(1);
  const querySnapshot = await query.get();
  querySnapshot.forEach((doc) => {
    productObj = doc.data;
    productObj.id = doc.id;
  });

  return productObj;
}

async function retrieveAll() {
  let query = db.collection("products");

  const querySnapshot = await query.get();

  let results = [];
  querySnapshot.forEach((doc) => {
    results.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return results;
}

async function createProduct(product) {
  let result = {
    isSuccess: false,
    product: product,
  }
  try {
    let productRef = await db.collection("products").add(product);
    result.product.id = productRef.id;
    result.isSuccess = true;
  } catch (error) {
    result.isSuccess = false;
  }

  return result;
}

async function updateProduct(product) {
  let result = {
    isSuccess: false,
    product: product,
  }

  try {
    // cleanup before saving
    product = formatUtil.cleanupFields(product);

    await db.collection("products").doc(product.id).set(product);
    result.isSuccess = true;
  } catch (error) {
    console.log(JSON.stringify(error));
    result.isSuccess = false;
  }

  return result;
}

export default {
  getProductsById,
  retrieveAll,
  createProduct,
  updateProduct,
};
