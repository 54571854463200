<template>
  <b-modal id="edit-product" size="lg" title="Edit Product" ref="modal" @ok="handleOk" @show="onReset"
    :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true"
    :no-close-on-esc="true">
    <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

    <b-form @submit.stop.prevent="handleSubmit" novalidate>
      <div class="form-row mt-4">
        <!-- Product Name Field -->
        <div class="col-md-6">
          <b-form-group label="Name" label-for="name" description>
            <b-form-input name="Name" type="text" v-model="form.name" v-validate="{
              required: true,
              regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,
            }" placeholder="Product Name" maxlength="100" />
            <span v-show="errors.has('Name')" class="help-block">
              {{ errors.first('Name') }}
            </span>
          </b-form-group>
        </div>
        <!-- Description Field -->
        <div class="col-md-6">
          <b-form-group label="Description" label-for="description">
            <b-form-input name="Description" type="text" v-model="form.description" v-validate="{
              required: true,
              regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,
            }" required placeholder="Description" maxlength="100" />
            <span v-show="errors.has('Description')" class="help-block">
              {{ errors.first('Description') }}
            </span>
          </b-form-group>
        </div>
      </div>
      <div class="form-row">
        <!-- Project Name -->
        <div class="col-md-4">
          <b-form-group label="Project Name" label-for="projectName" description>
            <b-form-input name="Project Name" type="text" v-model="form.projectName" v-validate="{
              required: true,
              regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,
            }" required placeholder="Project Name" maxlength="100" />
            <span v-show="errors.has('Project Name')" class="help-block">
              {{ errors.first('Project Name') }}
            </span>
          </b-form-group>
        </div>
        <!-- Type Field -->
        <div class="col-md-4">
          <b-form-group label="Type" label-for="type">
            <b-form-select name="Type" type="text" v-model="form.type" :options="options" v-validate="'required'" />
            <span v-show="errors.has('Type')" class="help-block">
              {{ errors.first('Type') }}
            </span>
          </b-form-group>
        </div>
        <!-- Client Name -->
        <div class="col-md-4">
          <b-form-group label="Client Name" label-for="clientName">
            <b-form-input id="clientName" name="Client Name" type="text" v-model="form.clientName" v-validate="{
              required: true,
              regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,
            }" placeholder="Client Name" maxlength="25" />
            <span v-show="errors.has('Client Name')" class="help-block">
              {{ errors.first('Client Name') }}
            </span>
          </b-form-group>
        </div>
      </div>
      <!-- isHighlight -->
      <div>
        <b-form-group label="Highlight?" label-for="ishighlight" description>
          <input class="ml-3" type="radio" id="true" value="true" v-model="form.isHighlight" />
          <label class="ml-2" for="true">Yes</label>

          <input class="ml-3" type="radio" id="false" value="false" v-model="form.isHighlight" />
          <label class="ml-2" for="false">No</label>
        </b-form-group>
      </div>

      <div class="form-row">
        <!-- Actual Image -->
        <div class="col-md-6">
          <b-form-group label="Actual Image" label-for="actualImage">
            <b-form-file id="actualImage" v-model="selectedImage" placeholder="Choose a file or drop it here..."
              ref="fileInput1" accept="image/*" @change="onSelectActualImage" no-drop>
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
              </template>
            </b-form-file>
            <p class="badge badge-danger bg-transparent ml-3" v-if="fileSizeExceeded1">
              File size exceeded the limit of {{ maxFileSize / 1000000 }} MB
            </p>
          </b-form-group>
          <!-- Preview Images -->
          <b-row v-if="actualImage.url" class="form-row">
            <b-col md="12">
              <b-card overlay :img-src="actualImage.url" img-alt="Card Image" text-variant="white">
                <b-card-text class="text-right">
                  <base-button type="danger" icon="fa fa-trash" size="sm" @click="onRemoveActualImage" />
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-else-if="actualImagePreviewUrl" class="form-row">
            <b-col md="12">
              <b-card overlay :img-src="actualImagePreviewUrl" img-alt="Card Image" text-variant="white"></b-card>
            </b-col>
          </b-row>
        </div>
        <!-- Product Image -->
        <div class="col-md-6">
          <b-form-group label="Product Image" label-for="productImage">
            <b-form-file id="productImage" v-model="selectedImage" placeholder="Choose a file or drop it here..."
              ref="fileInput2" accept="image/*" @change="onSelectProductImage" no-drop>
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
              </template>
            </b-form-file>
            <p class="badge badge-danger bg-transparent ml-3" v-if="fileSizeExceeded2">
              File size exceeded the limit of {{ maxFileSize / 1000000 }} MB
            </p>
          </b-form-group>
          <!-- Preview Images -->
          <b-row v-if="productImage.url" class="form-row">
            <b-col md="12">
              <b-card overlay :img-src="productImage.url" img-alt="Card Image" text-variant="white">
                <b-card-text class="text-right">
                  <base-button type="danger" icon="fa fa-trash" size="sm" @click="onRemoveProductImage" />
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-else-if="productImagePreviewUrl" class="form-row">
            <b-col md="12">
              <b-card overlay :img-src="productImagePreviewUrl" img-alt="Card Image" text-variant="white"></b-card>
            </b-col>
          </b-row>
        </div>
        <!-- Text Editor -->
        <b-form-group label="Specification" label-for="specification">
          <vue-editor v-model="form.specification" />
        </b-form-group>
      </div>
    </b-form>

    <template #modal-footer="{ ok, cancel }">
      <div class="row" v-if="uploading.uploadStatus === 'uploading'">
        <div class="col-md-12">
          <base-progress class="py-0 my-0" type="danger" :value="uploading.percentage" :label="uploading.filename"
            animated></base-progress>
        </div>
      </div>
      <div class="row" v-else-if="uploading.uploadStatus === 'success'">
        <div class="col-md-12">
          <b-alert variant="success" class="py-2 my-0" show>
            <i class="fa fa-check"></i>
            &nbsp; Successfully saved!
          </b-alert>
        </div>
      </div>

      <base-button type="secondary" class="text-dark" @click="cancel()">
        Close
      </base-button>
      <base-button type="primary" :disabled="isFileSizeExceeded" class="text-dark" @click="ok()">
        Save
      </base-button>
    </template>
  </b-modal>
</template>

<script>
// DAO
import productDAO from '../../database/products'

// Util
import dateUtil from '../../utils/dateUtil'

//Vue2Editor
import { VueEditor } from 'vue2-editor'

// Others
import { bus } from '../../main'
import { storage } from '../../config/firebase'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'edit-product',
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        type: null,
        isHighlight: null,
        projectName: '',
        specification: '',
        clientName: '',
        actualImage: {
          url: '',
        },
        productImage: {
          url: '',
        },
        isActive: true,
        createdBy: '',
        dateCreated: dateUtil.getCurrentTimestamp(),
        updatedBy: '',
        dateUpdated: dateUtil.getCurrentTimestamp(),
      },
      options: [
        { value: 'null', text: '- Please Select -' },
        { value: 'Pre-cast Fence', text: 'Pre-cast Fence' },
        { value: 'Hybrid CHB', text: 'Hybrid CHB' },
      ],
      actualImage: {},
      productImage: {},
      uploading: {
        filename: '',
        percentage: 0,
        uploadStatus: '', // [uploading, success, error]
      },

      // Check for loader
      isLoading: false,
      isUploading: false,
      loggedUser: this.$store.getters['auth/loggedUser'],
      maxFileSize: 2 * 1024 * 1024, //2MB
      selectedImage: null,
      fileSizeExceeded1: false,
      fileSizeExceeded2: false,
    }
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading
    },
    actualImagePreviewUrl() {
      return this.form.actualImage.url
    },
    productImagePreviewUrl() {
      return this.form.productImage.url
    },
    isFileSizeExceeded() {
      return this.selectedImage && this.selectedImage.size > this.maxFileSize
    },
  },
  methods: {
    async handleOk(evt) {
      evt.preventDefault()

      if (!(await this.$validator.validateAll())) {
        this.$toast.warning(
          'Please address the field/s with invalid input.',
          'Warning',
        )
        this.isLoading = false
        return
      }

      await this.handleSubmit()
    },
    async handleSubmit() {
      try {
        // show loading indicator
        this.isLoading = true

        // Update Timestamps & highlight
        this.form.updatedBy = this.loggedUser;
        this.form.dateUpdated = dateUtil.getCurrentTimestamp();

        // Save the product details to the database
        let product = this.form;

        const result = await productDAO.updateProduct(product);
        if (result.isSuccess) {
          console.log('finish saving data');
          if (this.actualImage._isNew && !this.isFileSizeExceeded) {
            product.actualImage = await this.firebaseUploadImage(this.actualImage);
            console.log('Done uploading actual image')
          }

          if (this.productImage._isNew && !this.isFileSizeExceeded) {
            product.productImage = await this.firebaseUploadImage(this.productImage);
            console.log('Done uploading product image')
          }

          await productDAO.updateProduct(product);

          this.uploadingInProgress('success')
          this.$toast.success(product.name + ' was updated.', 'Success')
          this.$refs.modal.hide()
          bus.$emit('onCloseEditProduct', product)

          console.log('done with all tasks')
        } else {
          this.$toast.error(
            "There is an error adding a product. Please try again.",
            "Error"
          );
        }
        setTimeout(() => { this.resetUploadingState() }, 2000)
      } catch (error) {
        console.error(error)
        this.$toast.error(
          'There is an error updating a product. Please try again.',
          'Error',
        )
      }
      // hide loading indicator
      this.isLoading = false
    },
    async firebaseUploadImage(image) {
      let filename = `PRODUCT-${Date.now()}`
      this.uploadingInProgress('uploading', filename, 0)

      return new Promise((resolve, reject) => {
        let storageRef = storage.ref(`images/products/${filename}`)
        let task = storageRef.put(image.file)

        task.on(
          'state_changed',
          (snapshot) => {
            let percentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            this.uploadingInProgress('uploading', filename, percentage)
          },
          (error) => {
            reject(error)
            this.resetUploadingState()
          },
          () => {
            task.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve({ name: filename, url: downloadURL })
            })
          },
        )
      })
    },
    uploadingInProgress(uploadStatus, filename = '', percentage = 0) {
      this.uploading.filename = filename
      this.uploading.percentage = parseFloat(percentage.toFixed(2));
      this.uploading.uploadStatus = uploadStatus
    },
    resetUploadingState() {
      this.uploading = {
        filename: '',
        percentage: 0,
        uploadStatus: '',
      }
    },
    onSelectActualImage(evt) {
      const file = evt.target.files[0]
      this.actualImage = {
        url: URL.createObjectURL(file),
        file: file,
        _isNew: true,
      }
      if (file.size > this.maxFileSize) {
        this.fileSizeExceeded1 = true
        this.selectedImage = null // Clear the selected file
        return
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        this.fileSizeExceeded1 = false
        // do something with the file
      }
      reader.readAsArrayBuffer(file)
    },
    onRemoveActualImage() {
      this.actualImage = ''
      this.$refs['fileInput1'].reset()
    },
    onSelectProductImage(evt) {
      const file = evt.target.files[0]
      this.productImage = {
        url: URL.createObjectURL(file),
        file: file,
        _isNew: true,
      }
      if (file.size > this.maxFileSize) {
        this.fileSizeExceeded2 = true
        this.selectedImage = null // Clear the selected file
        return
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        this.fileSizeExceeded2 = false
        // do something with the file
      }
      reader.readAsArrayBuffer(file)
    },
    onRemoveProductImage() {
      this.productImage = ''
      this.$refs['fileInput2'].reset()
    },
    onReset() {
      let product = this.$store.getters['products/currProduct']
      let productJSON = JSON.stringify(product)
      this.form = JSON.parse(productJSON)

      // reset validation
      this.isLoading = false
      this.$validator.reset()
      this.errors.clear()
      this.onRemoveActualImage()
      this.onRemoveProductImage()

      // this.actualImage = '';
      // this.productImage = '';
      this.images = {}
    },
  },
}
</script>
