<template>
  <div class="container-fluid">
    <b-card>
      <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

      <template v-slot:header>
        <i class="fa fa-list text-primary"></i>
        &nbsp;
        <strong>Products</strong>
        &nbsp;
        <small>List of Products Offered by EHI</small>
      </template>

      <b-card-text>
        <b-row class="mb-3">
          <b-col sm="6" md="3">
            <b-dropdown text=" Select Actions " variant="primary" slot="append">
              <b-dropdown-item v-b-modal.add-product>
                <i class="fa fa-plus"></i>
                Add New Products
              </b-dropdown-item>
              <b-dropdown-item v-b-modal.reorder-product @click="eventBus.$emit('ReorderProductDialog', items)">
                <i class="fa fa-sort"></i>
                Re-order Products
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col sm="6" md="4" offset-md="5" class="text-md-right">
            <base-dropdown position="right">
              <base-button slot="title" type="primary" class="dropdown-toggle">
                Show {{ perPage }} / Page
              </base-button>
              <b-dropdown-item v-for="(option, index) in pageOptions" :key="index" @click="perPage = option">
                {{ option }}
              </b-dropdown-item>
            </base-dropdown>
          </b-col>
        </b-row>

        <b-table :items="items" :fields="fields" selected-variant="primary" :current-page="currentPage"
          :per-page="perPage" :sort-by.sync="sortBy" responsive show-empty style="min-height: 380px;">
          <template v-slot:cell(dateUpdated)="{ item }">
            {{ item.dateUpdated | dateAndTimeFormatter }}
          </template>

          <template v-slot:cell(actions)="row">
            <b-button title="Show/Hide Other Details" variant="success" @click.stop="row.toggleDetails" size="sm">
              <b-icon-eye />
            </b-button>

            &nbsp;
            <b-button title="Edit Product" size="sm" v-b-modal.edit-product v-b-tooltip.hover.top="'Edit Product Detail'"
              variant="info" @click.stop="updateSelProduct(row.item)">
              <b-icon-pen />
            </b-button>
          </template>

          <template v-slot:cell(status)="row">
            <div>
              <a @click="updateSelProduct(row.item)">
                <b-form-checkbox name="status" v-b-modal.activate-product v-model="row.item.isActive" switch>
                  {{ row.item.isActive ? 'Active' : 'Inactive' }}
                </b-form-checkbox>
              </a>
            </div>
          </template>

          <template slot="row-details" slot-scope="row">
            <b-card>
              <b-tabs>
                <!-- Primary Details -->
                <b-tab title="Primary Details">
                  <b-row class="mx-2 my-3">
                    <b-input-group>
                      <b-card>
                        <b-col>
                          <p>
                            <span style="font-weight: 700;">Product Name:</span>
                            <span class="ml-3">{{ row.item.name }}</span>
                          </p>
                          <p>
                            <span style="font-weight: 700;">Description:</span>
                            <span class="ml-3">{{ row.item.description }}</span>
                          </p>
                          <p>
                            <span style="font-weight: 700;">Type:</span>
                            <span class="ml-3">{{ row.item.type }}</span>
                          </p>
                          <p>
                            <span style="font-weight: 700;">Client:</span>
                            <span class="ml-3">{{ row.item.clientName }}</span>
                          </p>
                          <p>
                            <span style="font-weight: 700;">Date Created:</span>
                            <span class="ml-3">
                              {{ row.item.dateCreated | dateAndTimeFormatter }}
                            </span>
                          </p>
                        </b-col>
                      </b-card>
                    </b-input-group>
                  </b-row>
                  <b-row class="mt-4 mb-2">
                    <b-col class="text-right">
                      <b-button @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover title="Hide Details"
                        size="sm">
                        <b-icon-arrow-up />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-tab>
                <!-- Specifications -->
                <b-tab title="Specification">
                  <b-row class="mx-2 my-3">
                    <b-input-group>
                      <b-col>
                        <p>
                          <span style="font-weight: 700;">Specification:</span>
                        </p>
                        <b-card v-if="row.item.specification">
                          <div v-html="row.item.specification"></div>
                        </b-card>
                        <b-card v-else>
                          <i>Not Available</i>
                        </b-card>
                      </b-col>
                    </b-input-group>
                  </b-row>
                  <b-row class="mt-4 mb-2">
                    <b-col class="text-right">
                      <b-button @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover title="Hide Details"
                        size="sm">
                        <b-icon-arrow-up />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Images">
                  <b-row class="mx-2 my-3">
                    <b-input-group>
                      <b-col>
                        <div class="container-fluid mt-3">
                          <h4>Actual Image</h4>
                          <img :src="row.item.actualImage.url" style="width: 100%;" class="img-fluid border border-dark"
                            alt="Image" />
                        </div>
                      </b-col>
                      <b-col>
                        <div class="container-fluid mt-3">
                          <h4>Product Image</h4>
                          <img :src="row.item.productImage.url" style="width: 100%;" class="img-fluid border border-dark"
                            alt="Image" />
                        </div>
                      </b-col>
                    </b-input-group>
                  </b-row>
                  <b-row class="mt-4 mb-2">
                    <b-col class="text-right">
                      <b-button @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover title="Hide Details"
                        size="sm">
                        <b-icon-arrow-up />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-card>
          </template>
        </b-table>
      </b-card-text>

      <template v-slot:footer>
        <b-row>
          <b-col md="8" sm="12" class="my-1">
            <span class="totalDisplay">Total: {{ items.length }}</span>
          </b-col>
          <b-col md="4" sm="12" class="my-1">
            <b-pagination align="right" :total-rows="items.length" :per-page="perPage" v-model="currentPage"
              class="my-0" />
          </b-col>
        </b-row>
      </template>
    </b-card>

    <!-- Modals -->
    <ReorderProduct />
    <AddProduct />
    <EditProduct />
    <ActivateProduct />
  </div>
</template>

<script>
// components
import ReorderProduct from './products/ReorderProduct'
import AddProduct from './products/AddProduct'
import EditProduct from './products/EditProduct'
import ActivateProduct from './products/ActivateProduct'

// DAO
import productDAO from '../database/products'

// others
import { bus } from '../main'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'products',
  components: {
    ReorderProduct,
    AddProduct,
    EditProduct,
    ActivateProduct,
    Loading,
  },
  data() {
    return {
      selProduct: {},
      allProductsObj: {},
      items: [],
      fields: [
        { key: 'order', label: 'Order', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'dateUpdated', label: 'Date Updated' },
        { key: 'status', label: 'Status' },
        { key: 'actions', label: 'Actions' },
      ],
      eventBus: bus,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, 25, 50, 75, 100],
			sortBy: 'order',

      isLoading: false,
    }
  },
  computed: {
    exportData() {
      let allProductsArr = []
      this.items.forEach((item) => {
        let product = Object.assign({}, item)
        product.isActive = item.isActive ? 'Active' : 'Inactive'
        allProductsArr.push(product)
      })
      return allProductsArr
    },
  },

  async mounted() {
    // pre-load products
    await this.getAllProducts()

    bus.$on('onCloseReOrderProduct', () => this.getAllProducts())
    bus.$on('onCloseAddProduct', () => this.getAllProducts())
    bus.$on('onCloseEditProduct', () => this.getAllProducts())
    bus.$on('onCloseActivateProduct', () => this.getAllProducts())
  },

  methods: {
    async getAllProducts() {
      try {
        this.isLoading = true
        this.allProductsObj = await productDAO.retrieveAll()
        this.items = Object.values(this.allProductsObj)
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.error(error)
      }
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    updateSelProduct(item) {
      this.selProduct = item
      this.$store.commit('products/SET_CURR_PRODUCT', item)
      bus.$emit('onUpdateSelProduct', item)
    },
  },

  beforeDestroy() {
    bus.$off('onCloseAddProduct')
    bus.$off('onCloseEditProduct')
    bus.$off('onCloseActivateProduct')
  },
}
</script>
