var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"id":"edit-product","size":"lg","title":"Edit Product","cancel-disabled":_vm.disableConfirmButtons,"ok-disabled":_vm.disableConfirmButtons,"no-close-on-backdrop":true,"no-close-on-esc":true},on:{"ok":_vm.handleOk,"show":_vm.onReset},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [(_vm.uploading.uploadStatus === 'uploading')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('base-progress',{staticClass:"py-0 my-0",attrs:{"type":"danger","value":_vm.uploading.percentage,"label":_vm.uploading.filename,"animated":""}})],1)]):(_vm.uploading.uploadStatus === 'success')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-alert',{staticClass:"py-2 my-0",attrs:{"variant":"success","show":""}},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" Successfully saved! ")])],1)]):_vm._e(),_c('base-button',{staticClass:"text-dark",attrs:{"type":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Close ")]),_c('base-button',{staticClass:"text-dark",attrs:{"type":"primary","disabled":_vm.isFileSizeExceeded},on:{"click":function($event){return ok()}}},[_vm._v(" Save ")])]}}])},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","color":"#20A8D8","is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('div',{staticClass:"form-row mt-4"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name","description":""}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,
          }),expression:"{\n            required: true,\n            regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,\n          }"}],attrs:{"name":"Name","type":"text","placeholder":"Product Name","maxlength":"100"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Name')),expression:"errors.has('Name')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Name'))+" ")])],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,
          }),expression:"{\n            required: true,\n            regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,\n          }"}],attrs:{"name":"Description","type":"text","required":"","placeholder":"Description","maxlength":"100"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Description')),expression:"errors.has('Description')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Description'))+" ")])],1)],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-4"},[_c('b-form-group',{attrs:{"label":"Project Name","label-for":"projectName","description":""}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,
          }),expression:"{\n            required: true,\n            regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,\n          }"}],attrs:{"name":"Project Name","type":"text","required":"","placeholder":"Project Name","maxlength":"100"},model:{value:(_vm.form.projectName),callback:function ($$v) {_vm.$set(_vm.form, "projectName", $$v)},expression:"form.projectName"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Project Name')),expression:"errors.has('Project Name')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Project Name'))+" ")])],1)],1),_c('div',{staticClass:"col-md-4"},[_c('b-form-group',{attrs:{"label":"Type","label-for":"type"}},[_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"Type","type":"text","options":_vm.options},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Type')),expression:"errors.has('Type')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Type'))+" ")])],1)],1),_c('div',{staticClass:"col-md-4"},[_c('b-form-group',{attrs:{"label":"Client Name","label-for":"clientName"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,
          }),expression:"{\n            required: true,\n            regex: /^([ÑA-Za-z0-9][0-9ñA-Za-z-(). ]{1,100})$/,\n          }"}],attrs:{"id":"clientName","name":"Client Name","type":"text","placeholder":"Client Name","maxlength":"25"},model:{value:(_vm.form.clientName),callback:function ($$v) {_vm.$set(_vm.form, "clientName", $$v)},expression:"form.clientName"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Client Name')),expression:"errors.has('Client Name')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Client Name'))+" ")])],1)],1)]),_c('div',[_c('b-form-group',{attrs:{"label":"Highlight?","label-for":"ishighlight","description":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.isHighlight),expression:"form.isHighlight"}],staticClass:"ml-3",attrs:{"type":"radio","id":"true","value":"true"},domProps:{"checked":_vm._q(_vm.form.isHighlight,"true")},on:{"change":function($event){return _vm.$set(_vm.form, "isHighlight", "true")}}}),_c('label',{staticClass:"ml-2",attrs:{"for":"true"}},[_vm._v("Yes")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.isHighlight),expression:"form.isHighlight"}],staticClass:"ml-3",attrs:{"type":"radio","id":"false","value":"false"},domProps:{"checked":_vm._q(_vm.form.isHighlight,"false")},on:{"change":function($event){return _vm.$set(_vm.form, "isHighlight", "false")}}}),_c('label',{staticClass:"ml-2",attrs:{"for":"false"}},[_vm._v("No")])])],1),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Actual Image","label-for":"actualImage"}},[_c('b-form-file',{ref:"fileInput1",attrs:{"id":"actualImage","placeholder":"Choose a file or drop it here...","accept":"image/*","no-drop":""},on:{"change":_vm.onSelectActualImage},scopedSlots:_vm._u([{key:"file-name",fn:function(ref){
          var names = ref.names;
return [_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v(_vm._s(names[0]))])]}}]),model:{value:(_vm.selectedImage),callback:function ($$v) {_vm.selectedImage=$$v},expression:"selectedImage"}}),(_vm.fileSizeExceeded1)?_c('p',{staticClass:"badge badge-danger bg-transparent ml-3"},[_vm._v(" File size exceeded the limit of "+_vm._s(_vm.maxFileSize / 1000000)+" MB ")]):_vm._e()],1),(_vm.actualImage.url)?_c('b-row',{staticClass:"form-row"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{attrs:{"overlay":"","img-src":_vm.actualImage.url,"img-alt":"Card Image","text-variant":"white"}},[_c('b-card-text',{staticClass:"text-right"},[_c('base-button',{attrs:{"type":"danger","icon":"fa fa-trash","size":"sm"},on:{"click":_vm.onRemoveActualImage}})],1)],1)],1)],1):(_vm.actualImagePreviewUrl)?_c('b-row',{staticClass:"form-row"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{attrs:{"overlay":"","img-src":_vm.actualImagePreviewUrl,"img-alt":"Card Image","text-variant":"white"}})],1)],1):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Product Image","label-for":"productImage"}},[_c('b-form-file',{ref:"fileInput2",attrs:{"id":"productImage","placeholder":"Choose a file or drop it here...","accept":"image/*","no-drop":""},on:{"change":_vm.onSelectProductImage},scopedSlots:_vm._u([{key:"file-name",fn:function(ref){
          var names = ref.names;
return [_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v(_vm._s(names[0]))])]}}]),model:{value:(_vm.selectedImage),callback:function ($$v) {_vm.selectedImage=$$v},expression:"selectedImage"}}),(_vm.fileSizeExceeded2)?_c('p',{staticClass:"badge badge-danger bg-transparent ml-3"},[_vm._v(" File size exceeded the limit of "+_vm._s(_vm.maxFileSize / 1000000)+" MB ")]):_vm._e()],1),(_vm.productImage.url)?_c('b-row',{staticClass:"form-row"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{attrs:{"overlay":"","img-src":_vm.productImage.url,"img-alt":"Card Image","text-variant":"white"}},[_c('b-card-text',{staticClass:"text-right"},[_c('base-button',{attrs:{"type":"danger","icon":"fa fa-trash","size":"sm"},on:{"click":_vm.onRemoveProductImage}})],1)],1)],1)],1):(_vm.productImagePreviewUrl)?_c('b-row',{staticClass:"form-row"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{attrs:{"overlay":"","img-src":_vm.productImagePreviewUrl,"img-alt":"Card Image","text-variant":"white"}})],1)],1):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Specification","label-for":"specification"}},[_c('vue-editor',{model:{value:(_vm.form.specification),callback:function ($$v) {_vm.$set(_vm.form, "specification", $$v)},expression:"form.specification"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }